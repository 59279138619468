.story-node-editor {
    background-color: #323232; /* Light purple background */
    padding: 20px;
    box-shadow: 0 2px 5px rgba(157, 230, 255, 0.2);
    font-family: Arial, sans-serif;
    color: #6b7fff; /* Text color matching your palette */
    height: 100%;
}

.editor-field {
    margin-bottom: 15px;
}

.editor-field-json {
    margin-bottom: 15px;
}

.editor-label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #ffffff; /* Pink color for labels */
}

.editor-input, .editor-textarea, .editor-textarea-json, .option-input {
    width: 95%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    background-color: #4c4c4c; /* Light background for inputs */
    color:white;
}

.editor-textarea {
    height: 200px; /* Fixed height for textareas */
}

.editor-textarea-json {
    height: 300px; /* Fixed height for textareas */
    resize: vertical; /* Allow vertical resize only */
}

.editor-options {
    border-top: 1px solid #ffdf28; /* Yellow line to separate options section */
    padding-top: 10px;
}

.option-item {
    margin-bottom: 10px;
}

.option-input {
    margin-right: 5px;
    width: calc(70% - 10px); /* Adjust width as needed */
}

.option-remove-btn, .option-add-btn, .save-node-btn {
    background-color: #6b7fff; /* Purple background for buttons */
    color: white;
    padding: 10px 10px;
    border: 10px;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s;
}

.option-remove-btn:hover, .option-add-btn:hover, .save-node-btn:hover {
    background-color: #5a68ff; /* Darker shade on hover */
}
