/* story-play.css */

/* Overlay styles for story display */
.story-overlay {
  position: absolute;
  /* Changed to absolute for positioning within the parent */
  top: 50%;
  /* Center vertically */
  left: 50%;
  /* Center horizontally */
  transform: translate(-50%, -50%);
  /* Adjust the position to truly center the element */
  width: 40%;
  /* Adjust the width to your preference */
  background-color: rgba(225, 209, 166, 0.952);
  /* Semi-transparent black background */
  display: flex;
  /* Use flexbox for alignment */
  flex-direction: column;
  /* Stack children vertically */
  align-items: center;
  /* Center children horizontally */
  justify-content: center;
  /* Center children vertically */
  padding: 20px;
  /* Padding around the content */
  box-shadow: -5px 0 15px rgba(0, 0, 0, 0.2);
  /* Shadow for depth, adjusted for right side */
  z-index: 1000;
  /* High z-index to cover other content */
  border-radius: 20px;
  /* Rounded corners */
}


/* Container for the story content */
.story-container {
  background-color: rgba(255, 255, 255, 0);
  /* White background for the container */
  padding: 0px;
  border-radius: 10px;
  /* Rounded corners */
  width: 100%;
  /* Adjust width as needed */
  max-height: 80%;
  /* Maximum height */
  max-height: 80%;
  /* Maximum height */
  overflow-y: auto;
  /* Scrollable if content exceeds height */
}

/* Character name styles */
.story-character-name {
  color:#373737;
  font-size: 30px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* Larger font size for character name */
  font-weight: bold;
  /* Bold font for emphasis */
  border-bottom: 1px solid #000;
  /* Adds a solid line. Change color as needed */
  padding-bottom: 10px;
  /* Adds space below the text and above the line */
  margin-bottom: 20px;
  /* Adds space below the line */
}

/* Story text styles */
.story-text {
  font-size: 28px;
  /* Font size for story text */
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  margin-bottom: 15px;
  /* Margin below the story text */
}

/* Navigation button styles */
.story-navigation button {
  background-color: #4CAF50;
  /* Green background for buttons */
  color: white;
  /* White text */
  padding: 10px 15px;
  /* Padding around text */
  border: none;
  /* No border */
  border-radius: 5px;
  /* Rounded corners */
  cursor: pointer;
  /* Pointer cursor on hover */
  margin-right: 10px;
  /* Space between buttons */
  transition: background-color 0.3s;
  /* Transition for hover effect */
}

.story-navigation button:hover {
  background-color: #45a049;
  /* Darker shade on hover */
}

/* Option button styles */
.story-options button {
  display: block;
  /* Full width */
  background-color: #444444e2;
  /* Blue background for options */
  color: rgb(226, 226, 226);
  /* White text */
  padding: 10px 15px;
  /* Padding around text */
  border: none;
  /* No border */
  border-radius: 15px;
  /* Rounded corners */
  cursor: pointer;
  /* Pointer cursor on hover */
  margin-top: 10px;
  /* Space between option buttons */
  margin-left: auto;
  /* Center align the button */
  margin-right: auto;
  /* Center align the button */
  transition: border 0.3s;
  /* Transition for hover effect */
  width: auto;
  min-width: 400px;
  font-size: 20px;
  border: 2px solid #00000000; /* Adds a border. Change color as needed */
}

.story-options button:hover {
  background-color: #9d9d9d;
  border: 2px solid #000; /* Adds a border. Change color as needed */
  /* Darker shade on hover */
}



/* end story button styles */
.end-story{
  display: block;
  /* Full width */
  background-color: #8a8a8ae2;
  /* Blue background for options */
  color: rgb(226, 226, 226);
  /* White text */
  padding: 10px 15px;
  /* Padding around text */
  border: none;
  /* No border */
  border-radius: 15px;
  /* Rounded corners */
  cursor: pointer;
  /* Pointer cursor on hover */
  margin-top: 10px;
  /* Space between option buttons */
  margin-left: auto;
  /* Center align the button */
  margin-right: auto;
  /* Center align the button */
  transition: border 0.3s;
  /* Transition for hover effect */
  width: auto;
  min-width: 400px;
  font-size: 20px;
  border: 2px solid #00000000; /* Adds a border. Change color as needed */
}

.end-story:hover {
  background-color: #9d9d9d;
  border: 2px solid #000; /* Adds a border. Change color as needed */
  /* Darker shade on hover */
}


.play-story-button,
.restart-story-button,
.visualize-button {
  background-color: #6b7fff;
  /* Lovely pinkish-red color */
  color: white;
  /* White text color */
  padding: 10px 15px;
  /* Padding around the text */
  font-size: 16px;
  /* Larger font size */
  border: none;
  /* No border */
  border-radius: 8px;
  /* Rounded corners */
  cursor: pointer;
  /* Cursor changes to pointer on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Slight shadow for depth */
  transition: all 0.3s ease;
  /* Smooth transition for hover effects */
  position: absolute;
  /* Absolute positioning */
  right: 10px;
  /* Positioned to the right */
  width: 200px;
}

.visualize-button {
  position: relative;
  margin-left: auto;
  /* Center align the button */
  margin-right: auto;
  /* Center align the button */
  left: 1px
}

.play-story-button {
  top: 10px;
  /* Positioned near the top */

}

.restart-story-button {
  top: 50px;
  /* Positioned slightly below the Play Story button */
}

.play-story-button:hover,
.restart-story-button:hover,
.visualize-button:hover {
  background-color: #ff8787;
  /* Slightly lighter color on hover */
}