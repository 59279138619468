.story-flow-node {
    background-color: #917bff85;
    padding: 5px;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(157, 230, 255, 0.2);
    font-family: Arial, sans-serif;
    text-align: center;
    border: 4px solid #6b7fff00; /* Place holder border to make sure layoutd does not change */
  }
  
  @keyframes borderAnimation {
    0% {
      border-color: #ff71ec; /* Starting color */
    }
    50% {
      border-color: #6b7fff; /* Ending color */
    }
    100% {
      border-color: #ff71ec; /* Loop color */
    }
  }
  /* New class for selected node */
  .story-flow-node.selected {
    border: 4px solid #6b7fff; /*  */
    animation: borderAnimation 2s infinite; /* Loop the animation */
  }
  
  .story-node {
    margin-bottom: 10px;
   
  }
  
  .story-node div {
    margin: 5px;
  }

  .story-node-id {
    font-size: 18px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color:#6b7fff;
    border-bottom :1px solid #ffdf28; /* Change the color and thickness as needed */
    margin-bottom: 2px; /* Optional: space below the line */
    padding-bottom: 2px; /* Optional: space above the line */
  }

  .story-text-container {
    padding: 2px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
    box-shadow: 2px 2px 5px rgb(0, 0, 0);
    width: 90%; /* Fixed width for the text container */
    height: 90%; /* Fixed height for the text container */
    overflow: hidden; /* Hide overflow */
    position: relative;
    text-align: center; /* Center align the text */
    max-height: 90%;
  }
  
  .story-text-container::after {
    content: "...";
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0 0 0 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
  }
  
  .story-text-container span {
    display: block;
    overflow: hidden;
    height: 100%;
    white-space: normal; /* Allow text to wrap */
    max-height: 70px;
  }
  
  
  .story-node strong {
    color: #ffffff;
  }
  
  /* Handle styles */
  .react-flow__handle.source {
    background: #555;
  }

  /* Style for text on the edges */
.react-flow__edge-text {
  fill: rgb(212, 212, 212);        /* Text color */
  font-size: 12px;   /* Text size */
  font-family: Arial, sans-serif; /* Font family */
}
  
.react-flow__edge-textbg {
  fill: #e9faff00; /* transparent */
}

  /* Additional styles */
  