
.generate-button {
    background-color: #6b7fff;  /* Lovely pinkish-red color */
    color: white;               /* White text color */
    padding: 10px 15px;         /* Padding around the text */
    font-size: 16px;            /* Larger font size */
    border: none;               /* No border */
    border-radius: 8px;         /* Rounded corners */
    cursor: pointer;            /* Cursor changes to pointer on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Slight shadow for depth */
    transition: all 0.3s ease;  /* Smooth transition for hover effects */
    
  }
  
.generate-button:hover {
    background-color: #ff8787;  /* Slightly lighter color on hover */
  }
  

  .story-play-control {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center items horizontally */
    justify-content: flex-start; /* Align items to the start of the column */
    height: 100%; /* Full height of the column */
  }

  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: rgb(255, 180, 67);
    animation: spin 1s ease infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  